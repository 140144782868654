import React from "react";
import { milestoneNameMap } from "~/utils/enum-mappers";
import { StatusTag } from "~/scalis-components/core";
import { Body, BodySize, BodyType } from "~/scalis-components/core/typography";
import { ApplicationStatusTag } from "../application-status";
import { getMilestoneStatusTagColor } from "./milestone-status-tag.utils";
import { MilestoneStatusTagProps } from "./milestone-status-tag.types";

export const MilestoneStatusTag = ({
  milestoneType,
  applicationStatus,
  lastUpdatedStatusAt,
}: MilestoneStatusTagProps) => {
  return (
    <StatusTag
      className="pr-1"
      contentContainerClassName="gap-[6px]"
      statusIconClassName={getMilestoneStatusTagColor(milestoneType)}
      small
      text={
        <div className="flex items-center gap-[6px]">
          {milestoneType && (
            <Body size={BodySize.small} type={BodyType.emphasys}>
              {milestoneNameMap[milestoneType]}
            </Body>
          )}
          {applicationStatus && (
            <ApplicationStatusTag
              applicationStatus={applicationStatus}
              lastUpdatedStatusAt={lastUpdatedStatusAt}
            />
          )}
        </div>
      }
    />
  );
};
