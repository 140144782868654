export const PASSWORD_VALIDATION_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[a-zA-Z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
export const PASSWORD_VALIDATION_MESSAGE =
  "Use at least 8 characters, with uppercase, lowercase, number and special character";

export const INVITE_CODE = "21f597d6-d1ac-4bf2-8343-a433b78418b1";

export const LABEL_BUTTON_CREATE_ACCOUNT = "Create account";

export const CALLBACK_SIGN_UP_URL = "/application/name-resume";
export const CALLBACK_LOGIN_URL = "/account-selector";

export enum AuthenticationActionEnum {
  SignUp = "signUp",
  Login = "login",
}
