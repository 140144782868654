"use client";

import { createContext } from "react";
import {
  EMPTY_CANDIDATE_APPLICATION_FILTERS_DATA,
  useCandidateApplicationFiltersData,
} from "../../../../src/app/company/(dashboard)/pipeline/hooks/use-get-candidate-application-filters-data";
import {
  FiltersContextProvider,
  useFiltersContext,
} from "~/scalis-components/team/contexts/filters-context";
import {
  CandidateApplicationFiltersContextProps,
  CandidateApplicationFiltersContextProviderInternalProps,
  CandidateApplicationFiltersContextProviderProps,
} from "./candidate-application-filters-context.types";
import {
  formDefaultValues,
  formSchema,
} from "./candidate-application-filters-context.schema";
import { mapRawFiltersToQueryFilters } from "./candidate-application-filters-context.utils";

export const CandidateApplicationFiltersContext =
  createContext<CandidateApplicationFiltersContextProps>({
    filters: mapRawFiltersToQueryFilters(formDefaultValues),
    filtersData: EMPTY_CANDIDATE_APPLICATION_FILTERS_DATA,
  });

const CandidateApplicationFiltersContextProviderInternal: React.FC<
  CandidateApplicationFiltersContextProviderInternalProps
> = ({ requisitionId, children }) => {
  const { filters } = useFiltersContext();

  const { data: filtersData } = useCandidateApplicationFiltersData({
    requisitionId,
  });

  return (
    <CandidateApplicationFiltersContext.Provider
      value={{ filters, filtersData }}
    >
      {children}
    </CandidateApplicationFiltersContext.Provider>
  );
};

const CandidateApplicationFiltersContextProviderWrapper = ({
  requisitionId,
  filterTabs,
  children,
}: CandidateApplicationFiltersContextProviderProps) => {
  return (
    <FiltersContextProvider
      formSchema={formSchema}
      formDefaultValues={formDefaultValues}
      mapRawFiltersToQueryFilters={mapRawFiltersToQueryFilters}
      filterTabs={filterTabs}
    >
      <CandidateApplicationFiltersContextProviderInternal
        requisitionId={requisitionId}
      >
        {children}
      </CandidateApplicationFiltersContextProviderInternal>
    </FiltersContextProvider>
  );
};

export { CandidateApplicationFiltersContextProviderWrapper as CandidateApplicationFiltersContextProvider };
