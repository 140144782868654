import React from "react";
import { pageButton } from "./page-buttons.styles";

interface PageButtonsProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export const PageButtons: React.FC<PageButtonsProps> = ({
  totalPages,
  currentPage,
  onPageChange,
}) => {
  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  const getFirstIndex = (currentPage: number) => {
    if (currentPage <= 3) {
      return 1;
    }

    if (currentPage >= totalPages - 2) {
      return totalPages - 3;
    }

    return currentPage - 2;
  };

  const firstIndex = getFirstIndex(currentPage);

  return (
    <div className="flex gap-1">
      {firstIndex > 1 && (
        <div className="px-3 py-1 text-typography-high-contrast">...</div>
      )}

      {Array.from({ length: 4 }, (_, index) => (
        <button
          key={`button-page-${index}`}
          className={pageButton({
            disabled: index + firstIndex === currentPage,
          })}
          disabled={index + firstIndex === currentPage}
          onClick={() => handlePageChange(index + firstIndex)}
        >
          {firstIndex + index}
        </button>
      ))}

      {firstIndex + 3 < totalPages && (
        <div className="px-3 py-1 text-typography-high-contrast">...</div>
      )}
    </div>
  );
};
