import { usePathname, useRouter, useSearchParams } from "next/navigation";

export const useSearchParamsManager = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const getParams = () => {
    return new URLSearchParams(searchParams?.toString());
  };

  const routerPush = (params: URLSearchParams) => {
    const urlParams = params ? `?${params}` : "";
    router.push(`${pathname}${urlParams}`);
  };

  const setQueryParam = (name: string, value: string) => {
    const params = getParams();
    params.set(name, value);

    routerPush(params);
  };

  const removeQueryParam = (name: string) => {
    const params = getParams();
    params.delete(name);

    routerPush(params);
  };

  const updateQueryParam = (name: string, value?: string) => {
    if (!value) return removeQueryParam(name);
    return setQueryParam(name, value);
  };

  return {
    searchParams,
    setQueryParam,
    removeQueryParam,
    updateQueryParam,
  };
};
