import { useGetCandidateApplicationFiltersDataQuery } from "~/bff/graphql/generated/graphql";
import * as filtersConst from "~/scalis-components/team/contexts/candidate-application-filters-context/candidate-application-filters-context.constants";
import {
  CandidateApplicationFiltersData,
  UseCandidateApplicationFiltersDataProps,
} from "./use-get-candidate-application-filters-data.types";
import { mapToOptions } from "./use-get-candidate-application-filters-data.utils";

export const useCandidateApplicationFiltersData = ({
  requisitionId,
}: UseCandidateApplicationFiltersDataProps) => {
  const {
    data: candidateApplicationFiltersData,
    loading: isLoading,
    error,
  } = useGetCandidateApplicationFiltersDataQuery({
    variables: { requisitionId },
    skip: !requisitionId,
  });

  const filtersData =
    candidateApplicationFiltersData?.GetCandidateApplicationFilterContent;

  const data: CandidateApplicationFiltersData = {
    [filtersConst.FIELD_NAME_CANDIDATE_PROFILE_ID]: mapToOptions(
      filtersData?.candidateNames,
      { idAsValue: true },
    ),
    [filtersConst.FIELD_NAME_COMPANY]: mapToOptions(filtersData?.companies),
    [filtersConst.FIELD_NAME_DEGREE]: mapToOptions(filtersData?.degrees),
    [filtersConst.FIELD_NAME_EMAIL]: mapToOptions(filtersData?.emails, {
      idAsValue: true,
    }),
    [filtersConst.FIELD_NAME_JOB_TITLE]: mapToOptions(filtersData?.jobTitles),
    [filtersConst.FIELD_NAME_MAJORS]: mapToOptions(filtersData?.majors),
    [filtersConst.FIELD_NAME_SCHOOLS]: mapToOptions(filtersData?.schools),
    [filtersConst.FIELD_NAME_SKILLS]: mapToOptions(filtersData?.skills),
    [filtersConst.FIELD_NAME_SCREENED_BY]: mapToOptions(
      filtersData?.creditedCompanyUser,
      { idAsValue: true },
    ),
  };

  return { data, isLoading, error };
};
