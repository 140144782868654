import { square, titleStyles } from "./candidate-list-view-title.styled";
import { CandidateListViewTitleProps } from "./candidate-list-view-title.types";
import { StageSettings } from "./components/stage-settings";
import { StageCollaborators } from "./components/stage-collaborators";

export const CandidateListViewTitle: React.FC<CandidateListViewTitleProps> = ({
  title,
  squareColor,
  action = null,
  stageId,
}) => {
  const { container, text } = titleStyles({ hasAction: !!action });

  const renderTitle = () => {
    if (Array.isArray(title)) {
      return (
        <>
          {title[0]} <span className="text-xs">({title[1]})</span>
        </>
      );
    }

    return title;
  };

  return (
    <div className="flex items-center gap-2">
      <div className={container()} onClick={!!action ? action : () => null}>
        <div className={square({ color: squareColor })} />
        <h3 className={text()}>{renderTitle()}</h3>
      </div>

      <StageSettings />

      <StageCollaborators stageId={stageId} />
    </div>
  );
};
