import { CandidateListViewViewer } from "~/scalis-components/pipeline/list-view/candidate-list-view-viewer";
import { MilestoneDetailListViewProps } from "./milestone-detail-list-view.types";
import { getMappedMilestone } from "./milestone-detail-list-utils";

export const MilestoneDetailListView: React.FC<
  MilestoneDetailListViewProps
> = props => {
  const mappedMilestone = getMappedMilestone(props);

  return <CandidateListViewViewer {...mappedMilestone} />;
};
