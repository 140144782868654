import React from "react";
import { ActionButtonsProps } from "./action-buttons.types";
import { Button } from "~/scalis-components/core/button";
import Link from "next/link";

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  actionBarItems,
  candidate,
}) => {
  const filteredActionBarItems = actionBarItems.filter(({ renderIf }) => {
    if (renderIf && !renderIf(candidate)) return false;
    return true;
  });

  const renderButton = ({
    id,
    label,
    href,
    ...props
  }: ActionButtonsProps["actionBarItems"][0]) => {
    if (href) {
      return (
        <Link href={href}>
          <Button key={id} text={label} {...props} />
        </Link>
      );
    }

    return <Button key={id} text={label} {...props} />;
  };

  return <>{filteredActionBarItems.map(renderButton)}</>;
};
