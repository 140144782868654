import { forwardRef, useContext, useMemo } from "react";
import { CandidateListViewProps } from "./candidate-list-view.types";
import { getCandidateListViewColumns } from "./candidate-list-view.utils";
import { ListViewPagination } from "~/scalis-components/core/list-view/components/list-view-pagination";
import {
  GlobalListViewContext,
  ListView,
} from "~/scalis-components/core/list-view";
import { CandidateActionBar } from "../candidate-action-bar";

export const CandidateListView = forwardRef<
  HTMLDivElement,
  CandidateListViewProps
>(
  (
    {
      rows,
      rowActionsMenuItems,
      tableColor,
      id,
      excludeColumns,
      actionsBarItems,
      showActionBar = true,
      showSeeAllCandidates = false,
      allCandidatesCallback = () => {},
    },
    ref,
  ) => {
    const { hideAllActionBars } = useContext(GlobalListViewContext);

    const columns = useMemo(() => {
      const listViewColumns = getCandidateListViewColumns(
        showSeeAllCandidates,
        allCandidatesCallback,
      );

      if (!excludeColumns) {
        return listViewColumns;
      }

      return listViewColumns.filter(c => !excludeColumns.includes(c.key));
    }, [excludeColumns]);

    return (
      <ListView
        id={id}
        ref={ref}
        rows={rows}
        columns={columns}
        tableColor={tableColor}
        rowActionsMenuItems={rowActionsMenuItems}
      >
        {showActionBar && (
          <CandidateActionBar
            hideAllActionBars={hideAllActionBars}
            actionsBarItems={actionsBarItems}
          />
        )}
        <ListViewPagination />
      </ListView>
    );
  },
);
