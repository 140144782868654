import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React from "react";
import { Button } from "~/components/v2/Button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/v2/Drawer";
import { useApplyCandidateToJob } from "~/hooks/applications/useApplyCandidateToJob";
import { useToast } from "~/hooks/useToast";
import { errorHandler } from "~/utils/error";
import logger from "~/utils/logger";
import {
  AdvanceCandidateContext,
  PopUpType,
} from "~app/company/(dashboard)/pipeline/components/advance-candidate-context";
import { POPUP_TYPE_ALREADY_MATCHED_TO_JOB_MODAL } from "~app/company/(dashboard)/pipeline/components/modals/application-already-exists-modal";
import { MatchCandidateDrawerContext } from "./components";
import {
  FormValues,
  MatchCandidateDrawerForm,
} from "./components/match-candidate-drawer-form";
import { ERROR_MESSAGE_APPLICATION_ALREADY_EXISTS } from "./match-candidate-drawer.constants";
import { MatchCandidateDrawerProps } from "./match-candidate-drawer.types";
import { parseFormData } from "./match-candidate-drawer.utils";

export const MatchCandidateDrawer = ({
  open,
  onOpenChange,
  jobSeekerId,
  uploadedCandidateId,
  candidateName,
  onSubmit,
}: MatchCandidateDrawerProps) => {
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { setOpen, setJobNumber, setJobTitle } = React.useContext(
    AdvanceCandidateContext,
  );
  const { mutate, isPending: isLoading } = useApplyCandidateToJob({
    onSuccess: () => {
      onOpenChange?.(false);
      queryClient.invalidateQueries({ queryKey: ["milestones"] });
      queryClient.invalidateQueries({ queryKey: ["past-candidates"] });
    },
    onError: (e: any) => {
      if (
        e?.response?.data?.message === ERROR_MESSAGE_APPLICATION_ALREADY_EXISTS
      ) {
        setOpen?.(POPUP_TYPE_ALREADY_MATCHED_TO_JOB_MODAL as PopUpType);
        const { jobId, jobTitle } = e?.response?.data?.data || {};
        setJobNumber?.(jobId);
        setJobTitle?.(jobTitle);
        return;
      }
      errorHandler(toast, e as AxiosError<any>);
    },
  });
  const { defaultMessages } = React.useContext(MatchCandidateDrawerContext);

  const handleSubmit = (data: FormValues) => {
    const body = parseFormData(
      data,
      jobSeekerId,
      uploadedCandidateId,
      defaultMessages,
    );
    setIsSubmit(false);
    mutate(body);
  };

  const onErrorSubmit = (error: any) => {
    logger.error(error);
    setIsSubmit(false);
  };

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent asChild>
        <div>
          <DrawerHeader className="text-center">
            <h1 className="text-xl font-semibold text-typography-high-contrast">
              Match Candidate
            </h1>
            <h2 className="text-sm text-typography-low-contrast">
              {candidateName}
            </h2>
          </DrawerHeader>
          <MatchCandidateDrawerForm
            onSubmit={(data: FormValues) => {
              if (onSubmit) {
                onSubmit(data);
                return;
              }
              handleSubmit(data);
            }}
            isSubmit={isSubmit}
            onErrorSubmit={onErrorSubmit}
          />
          <DrawerFooter className="justify-end">
            <DrawerClose asChild>
              <Button variant="outline">Cancel</Button>
            </DrawerClose>
            <Button
              aria-label="Confirm"
              className="min-w-20"
              isLoading={isLoading}
              onClick={() => setIsSubmit(true)}
            >
              Confirm
            </Button>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
