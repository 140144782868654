import { NameEnum } from "~/bff/graphql/generated/graphql";
import { Routes } from "~/src/app/_constants/routes";
import { ActionBarItem } from "~/scalis-components/pipeline/list-view/candidate-action-bar/candidate-action-bar.types";
import { Candidate } from "~/scalis-components/pipeline/list-view/candidate-list-view";
import useAdvanceCandidate from "~/hooks/candidate/useAdvanceCandidate";
import {
  AdvanceProps,
  MilestoneProps,
  MatchTojobProps,
  RejectProps,
} from "./candidate-list-view-utils.types";
import { useRejectCandidate } from "~app/company/(dashboard)/pipeline/components/reject-candidates";
import { TableColorTypes } from "~/scalis-components/core/list-view/list-view.types";
import { ButtonKind } from "~/scalis-components/core/button";

export const actionItemDetails = {
  chat: {
    label: "Chat",
  },
  scheduleInterview: {
    label: "Schedule Interview",
  },
  advance: {
    label: "Advance",
  },
  reject: {
    label: "Reject",
  },
  makeOffer: {
    label: "Make offer",
  },
  matchToJob: {
    label: "Match to job",
  },
  sendSchedulerLink: {
    label: "Send Scheduler Link",
  },
  sendToHris: {
    label: "Send to HRIS",
  },
};

const isScalisProfile = (candidate?: Candidate) => !!candidate?.seekerId;

export const getRowActionsMenuItems = (props?: MilestoneProps) => {
  const action = useAdvanceCandidate();
  const { setPreviousSelectCandidates, setOpenDrawer: setOpenRejectDrawer } =
    useRejectCandidate();

  const chat = {
    label: actionItemDetails.chat.label,
    href: Routes.company.messaging,
    id: actionItemDetails.chat.label,
    icon: "fa-regular fa-message-lines",
    renderIf: isScalisProfile,
  };

  const makeOffer = {
    label: actionItemDetails.makeOffer.label,
    onClick: () => {},
    id: actionItemDetails.makeOffer.label,
    icon: "fa-regular fa-file-signature",
  };

  const advance = {
    label: actionItemDetails.advance.label,
    onClick: ({
      name,
      applicationType,
      seekerId,
      uploadedCandidateId,
      applicationId,
      alreadyInvitedToJob,
      recentlyInvitedToAnotherJob,
    }: AdvanceProps) => {
      return action({
        candidateName: name,
        companyId: Number(props?.companyId),
        isFromTalentPool: false,
        applicationType,
        jobSeekerId: seekerId,
        uploadedCandidateId,
        applicationId,
        stageId: props?.nextStageId ?? -1,
        jobNumber: props?.requisitionId,
        jobTitle: props?.jobTitle,
        milestoneType: props?.milestoneType,
        alreadyInvitedToJob,
        recentlyInvitedToAnotherJob,
      })();
    },
    id: actionItemDetails.advance.label,
    icon: "fa-regular fa-circle-check",
  };

  const scheduleInterview = {
    label: actionItemDetails.scheduleInterview.label,
    onClick: () => {},
    id: actionItemDetails.scheduleInterview.label,
    icon: "fa-regular fa-calendar-day",
  };

  const reject = {
    label: actionItemDetails.reject.label,
    onClick: ({ name, email, applicationId }: RejectProps) => {
      setPreviousSelectCandidates([{ id: applicationId, name, email }]);
      setOpenRejectDrawer(true);
    },
    id: actionItemDetails.reject.label,
    icon: "fa-regular fa-circle-x",
  };

  const matchToJob = {
    label: actionItemDetails.matchToJob.label,
    onClick: ({
      name,
      applicationType,
      seekerId,
      uploadedCandidateId,
      applicationId,
      nextStageId,
      jobRequisitionNumber,
      jobTitle,
      milestoneType,
      alreadyInvitedToJob,
      recentlyInvitedToAnotherJob,
    }: MatchTojobProps) => {
      return action({
        candidateName: name,
        companyId: Number(props?.companyId),
        isFromTalentPool: true,
        applicationType,
        jobSeekerId: seekerId,
        uploadedCandidateId,
        applicationId,
        stageId: nextStageId ?? -1,
        jobNumber: jobRequisitionNumber,
        jobTitle,
        milestoneType,
        alreadyInvitedToJob,
        recentlyInvitedToAnotherJob,
      })();
    },
    id: actionItemDetails.matchToJob.label,
    icon: "fa-regular fa-briefcase",
  };

  const sendToHris = {
    label: actionItemDetails.sendToHris.label,
    onClick: () => {},
    id: actionItemDetails.sendToHris.label,
    icon: "fa-regular fa-bolt",
  };

  return {
    chat,
    makeOffer,
    advance,
    reject,
    matchToJob,
    scheduleInterview,
    sendToHris,
  };
};

export const getRowActionsBarItems = (props?: MilestoneProps) => {
  const {
    matchToJob,
    chat,
    makeOffer,
    advance,
    reject,
    scheduleInterview,
    sendToHris,
  } = getRowActionsMenuItems(props);

  const { setOpenDrawer: setOpenRejectDrawer } = useRejectCandidate();

  const chatBar = {
    ...chat,
    endAdornment: chat.icon,
    kind: ButtonKind.tertiary,
  };

  const makeOfferBar = {
    ...makeOffer,
    endAdornment: makeOffer.icon,
    kind: ButtonKind.tertiary,
  };

  const advanceBar = {
    ...advance,
    endAdornment: advance.icon,
    kind: ButtonKind.tertiary,
  };

  const scheduleInterviewBar = {
    ...scheduleInterview,
    endAdornment: scheduleInterview.icon,
    kind: ButtonKind.tertiary,
  };

  const sendSchedulerLink = {
    label: actionItemDetails.sendSchedulerLink.label,
    onClick: () => {},
    id: actionItemDetails.sendSchedulerLink.label,
    endAdornment: "fa-regular fa-calendar-day",
    kind: ButtonKind.tertiary,
  };

  const rejectBar = {
    ...reject,
    onClick: () => {
      setOpenRejectDrawer(true);
    },
    endAdornment: reject.icon,
    kind: ButtonKind.critical,
  };

  const matchToJobBar = {
    ...matchToJob,
    id: actionItemDetails.sendSchedulerLink.label,
    endAdornment: matchToJob.icon,
  };

  const sendToHrisBar = {
    ...sendToHris,
    endAdornment: sendToHris.icon,
    kind: ButtonKind.tertiary,
  };

  return {
    chat: chatBar,
    makeOffer: makeOfferBar,
    advance: advanceBar,
    reject: rejectBar,
    matchToJob: matchToJobBar,
    scheduleInterview: scheduleInterviewBar,
    sendSchedulerLink,
    sendToHris: sendToHrisBar,
  };
};

export const getMilestoneTypeProps = (props: MilestoneProps) => {
  const { advance, chat, reject, scheduleInterview, sendToHris } =
    getRowActionsMenuItems(props);
  const {
    chat: chatAction,
    advance: advanceAction,
    reject: rejectAction,
    scheduleInterview: scheduleInterviewAction,
    sendSchedulerLink,
    sendToHris: sendToHrisAction,
  } = getRowActionsBarItems(props);

  switch (props.milestoneType) {
    case NameEnum.Sourcing: {
      return {
        color: "01" as TableColorTypes,
        rowActionsMenuItems: [[chat, advance, reject]],
        actionsBarItems: {
          single: [chatAction, advanceAction, rejectAction] as ActionBarItem[],
          multiple: [advanceAction, rejectAction] as ActionBarItem[],
        },
      };
    }

    case NameEnum.ApplicationReview: {
      return {
        color: "02" as TableColorTypes,
        rowActionsMenuItems: [[chat, scheduleInterview, advance, reject]],
        actionsBarItems: {
          single: [
            chatAction,
            scheduleInterviewAction,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
          multiple: [
            sendSchedulerLink,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
        },
      };
    }

    case NameEnum.PreScreen: {
      return {
        color: "03" as TableColorTypes,
        rowActionsMenuItems: [[chat, scheduleInterview, advance, reject]],
        actionsBarItems: {
          single: [
            chatAction,
            scheduleInterviewAction,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
          multiple: [
            sendSchedulerLink,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
        },
      };
    }

    case NameEnum.Interviewing: {
      return {
        color: "04" as TableColorTypes,
        rowActionsMenuItems: [[chat, scheduleInterview, advance, reject]],
        actionsBarItems: {
          single: [
            chatAction,
            scheduleInterviewAction,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
          multiple: [
            sendSchedulerLink,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
        },
      };
    }

    case NameEnum.Offer: {
      return {
        color: "05" as TableColorTypes,
        rowActionsMenuItems: [[chat, scheduleInterview, advance, reject]],
        actionsBarItems: {
          single: [
            chatAction,
            scheduleInterviewAction,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
          multiple: [
            sendSchedulerLink,
            advanceAction,
            rejectAction,
          ] as ActionBarItem[],
        },
      };
    }

    case NameEnum.Hired: {
      return {
        color: "06" as TableColorTypes,
        rowActionsMenuItems: [[chat, sendToHris]],
        actionsBarItems: {
          single: [chatAction, sendToHrisAction] as ActionBarItem[],
          multiple: [] as ActionBarItem[],
        },
      };
    }

    default: {
      return {
        color: "01" as TableColorTypes,
        rowActionsMenuItems: [[]],
        actionsBarItems: {
          single: [] as ActionBarItem[],
          multiple: [] as ActionBarItem[],
        },
      };
    }
  }
};

export const getTitle = (milestoneName: string, stageName: string) => {
  if (milestoneName === stageName) {
    return milestoneName;
  }

  return [stageName, milestoneName];
};
