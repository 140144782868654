import { ApplicationType } from "@prisma/client";
import { NameEnum } from "~/bff/graphql/generated/graphql";
import { useAdvanceCandidatePopUps } from "~app/company/(dashboard)/pipeline/components/advance-candidate-context";
import {
  POPUP_TYPE_ALREADY_INVITED_TO_ANOTHER_JOB_MODAL,
  POPUP_TYPE_ALREADY_INVITED_TO_SAME_JOB_MODAL,
} from "~app/company/(dashboard)/pipeline/components/modals/application-already-exists-modal";
import { useActions } from "~app/company/(dashboard)/pipeline/components/useActions";
import {
  POPUP_TYPE_ADVANCE_CANDIDATE_DRAWER,
  POPUP_TYPE_MATCH_CANDIDATE_DRAWER,
  POPUP_TYPE_UPLOAD_CANDIDATE_DRAWER,
} from "~/scalis-components";

const useAdvanceCandidate = () => {
  const { dispatch, setStage } = useActions();
  const {
    setApplicationId,
    setApplicationType,
    setCandidateName,
    setCompanyId,
    setStageId,
    onOpenChange,
    setJobNumber,
    setJobTitle,
    setAlreadyInvitedToJob,
    setRecentlyInvitedToAnotherJob,
    setJobSeekerId,
    setUploadedCandidateId,
  } = useAdvanceCandidatePopUps();

  const advance =
    ({
      applicationId,
      applicationType,
      candidateName,
      companyId,
      stageId,
      jobNumber,
      jobTitle,
      milestoneType,
      alreadyInvitedToJob,
      recentlyInvitedToAnotherJob,
      actionType = "advance",
      isFromTalentPool,
      jobSeekerId,
      uploadedCandidateId,
      isFromUpload,
    }: {
      applicationId?: number;
      applicationType?: ApplicationType;
      candidateName?: string;
      companyId: number;
      stageId?: number;
      jobNumber?: number;
      jobTitle?: string;
      milestoneType?: NameEnum | null;
      alreadyInvitedToJob?: boolean;
      recentlyInvitedToAnotherJob?: boolean;
      actionType?: "advance" | "set";
      isFromTalentPool?: boolean;
      jobSeekerId?: number;
      uploadedCandidateId?: number;
      isFromUpload?: boolean;
    }) =>
    () => {
      setApplicationId(applicationId!);
      setApplicationType?.(applicationType!);
      setCandidateName(candidateName!);
      setCompanyId(companyId);
      setJobSeekerId?.(jobSeekerId!);
      setUploadedCandidateId?.(uploadedCandidateId!);
      setStageId(stageId!);
      setJobNumber(jobNumber);
      setJobTitle(jobTitle);
      setAlreadyInvitedToJob(alreadyInvitedToJob!);
      setRecentlyInvitedToAnotherJob(recentlyInvitedToAnotherJob!);

      const isStatic =
        !jobSeekerId && applicationType === ApplicationType.STATIC;

      const isFromSourcing = milestoneType === NameEnum.Sourcing;
      const isFromProcessing = milestoneType !== NameEnum.Sourcing;

      if (isFromTalentPool) {
        return onOpenChange(POPUP_TYPE_MATCH_CANDIDATE_DRAWER);
      }
      if (isFromUpload) {
        return onOpenChange(POPUP_TYPE_UPLOAD_CANDIDATE_DRAWER);
      }
      if (isFromSourcing) {
        if ((alreadyInvitedToJob || recentlyInvitedToAnotherJob) && !isStatic) {
          return onOpenChange(
            alreadyInvitedToJob
              ? POPUP_TYPE_ALREADY_INVITED_TO_SAME_JOB_MODAL
              : POPUP_TYPE_ALREADY_INVITED_TO_ANOTHER_JOB_MODAL,
          );
        }
        return onOpenChange(
          isStatic ? "invite_uploaded_candidate" : "invite_scalis_candidate",
        );
      }
      if (isFromProcessing) {
        return onOpenChange(POPUP_TYPE_ADVANCE_CANDIDATE_DRAWER);
      }
      if (actionType === "set") {
        return setStage(applicationId!, stageId!);
      }
      dispatch({ action: "advance", candidateId: applicationId! });
    };

  return advance;
};

export default useAdvanceCandidate;
