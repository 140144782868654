import { useRef } from "react";
import { cn } from "~/utils/cn";
import { EmptyState } from "~/scalis-components/core";
import { LoadingState } from "~/scalis-components/team";
import { CandidateListView } from "~/scalis-components/pipeline/list-view/candidate-list-view";
import { CandidateListViewTitle } from "../candidate-list-view-title";
import { CandidateListViewViewerProps } from "./candidate-list-view-viewer.types";

export const CandidateListViewViewer: React.FC<
  CandidateListViewViewerProps
> = ({
  squareColor,
  title,
  listView,
  className,
  id,
  showActionBar,
  excludeColumns = [],
  candidates,
  isLoading,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="h-full">
      <CandidateListViewTitle
        stageId={Number(id)}
        squareColor={squareColor}
        title={title}
      />

      <div
        className={cn("list-view-container mt-5 overflow-auto", className)}
        ref={containerRef}
      >
        <div className="pb-4">
          {(!candidates.length || isLoading) && (
            <div className="rounded-xl border border-solid border-borders-borders p-8">
              {isLoading && <LoadingState />}

              {!isLoading && (
                <EmptyState
                  title="No candidates found!"
                  message="We're sorry, but it seems like there are no candidates for you to review at this stage."
                  icon="fa-regular fa-magnifying-glass"
                  iconSize="fa-3x"
                />
              )}
            </div>
          )}

          {!isLoading && !!candidates.length && (
            <CandidateListView
              {...listView}
              rows={candidates}
              id={id}
              ref={containerRef}
              excludeColumns={excludeColumns}
              showActionBar={showActionBar}
            />
          )}
        </div>
      </div>
    </div>
  );
};
