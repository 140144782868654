import { UniqueIdentifier } from "@dnd-kit/core";
import { Column, ColumnDef, Table } from "@tanstack/react-table";
import { merge } from "lodash";
import { CSSProperties } from "react";
import { ActionsMenu } from "./components/actions-menu";
import { ListViewCheckbox } from "./components/list-view-checkbox";
import { AddTableButton } from "./components/list-view-header/components/add-table-button";
import { ActionsMenuItems, ListViewColumn, Position } from "./list-view.types";
import { STATUS } from "~/components/v2/status";

export const defaultColumnsID = {
  select: "select",
  rowActions: "rowActions",
  addColumn: "addColumn",
} as const;

export const TABLE_COLOR_COLUMN_WIDTH = 8;
export const TABLE_HEADER_HEIGHT = 38;
export const TABLE_HEADER_MIN_WIDTH = 75;

const hideAddColumn = (table: Table<unknown>) => {
  const addColumn = table.getColumn(defaultColumnsID.addColumn);

  addColumn?.toggleVisibility(false);
};

const getAddcolumnOptions = (
  table: Table<unknown>,
  onAddColumn: (columnId: string) => void,
) => {
  const columnVisibility = table.getState().columnVisibility;

  const hiddenColumns = Object.keys(columnVisibility).filter(
    id => !columnVisibility[id],
  );

  return hiddenColumns.map(id => {
    const currentColumn = table
      .getAllLeafColumns()
      .find(column => column.id === id);

    return {
      handleClick: () => {
        onAddColumn(id);
        if (hiddenColumns.length === 1) hideAddColumn(table);
        currentColumn?.toggleVisibility(true);
      },
      label: String(currentColumn?.columnDef.header),
      id,
    };
  });
};

export const getTableColumns = ({
  columns,
  rowActionsMenuItems,
  onAddColumn,
}: {
  columns: ListViewColumn[];
  rowActionsMenuItems: ActionsMenuItems[][];
  onAddColumn: (columnId: string) => void;
}): ColumnDef<any>[] => {
  const createColumns = columns.map(
    ({ key, value, meta, size = 150, footer }) => {
      const defaultMeta = {
        header: { isDraggable: true, hasActions: true, canHide: true },
        rows: { isDraggable: false },
      };

      return {
        accessorKey: key,
        header: value,
        id: key,
        enableResizing: true,
        minSize: TABLE_HEADER_MIN_WIDTH,
        meta: merge(defaultMeta, meta),
        size,
        footer,
        cell: ({ getValue, row }) =>
          meta?.rows?.contentRender
            ? meta?.rows?.contentRender(getValue(), row)
            : getValue(),
      } as ColumnDef<unknown>;
    },
  );

  return [
    {
      accessorKey: defaultColumnsID.rowActions,
      id: defaultColumnsID.rowActions,
      header: () => null,
      cell: ({ row }) => {
        if (row.original?.status === STATUS.REJECTED.key) {
          return null;
        }

        return (
          <div className="flex h-full items-center justify-center">
            <ActionsMenu actionsMenuItems={rowActionsMenuItems} row={row} />
          </div>
        );
      },
      size: 32,
      enableResizing: false,
      enablePinning: true,
      meta: {
        isPinned: true,
        header: {
          styles: {
            noBorders: true,
            hidden: true,
            noHoverBg: true,
          },
        },
        rows: {
          dndNodeRef: true,
          styles: {
            noBorders: true,
            hidden: true,
          },
        },
      },
    },
    {
      accessorKey: defaultColumnsID.select,
      id: defaultColumnsID.select,
      header: ({ table }) => (
        <ListViewCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <ListViewCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled:
              !row.getCanSelect() ||
              row.original?.status === STATUS.REJECTED.key,
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      size: 40,
      enableResizing: false,
      enablePinning: true,
      meta: {
        isPinned: true,
      },
    },
    ...createColumns,
    {
      accessorKey: defaultColumnsID.addColumn,
      id: defaultColumnsID.addColumn,
      header: ({ table }) => {
        const options = getAddcolumnOptions(table, onAddColumn);
        return <AddTableButton options={options} />;
      },
      cell: () => <div className="px-5" />,
      enableResizing: false,
      meta: {
        header: {
          isDraggable: false,
          styles: {
            noHoverBg: true,
          },
        },
      },
    },
  ];
};

export const getCellInsertPosition = ({
  overId,
  currentId,
  index,
  activeCellIndex,
}: {
  overId: UniqueIdentifier;
  currentId: string;
  index: number;
  activeCellIndex: number;
}) => {
  if (overId === currentId) {
    if (index > activeCellIndex) {
      return Position.After;
    }
    return Position.Before;
  }
};

export const getCommonPinningStyles = (column: Column<any>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const columnGetStart = column.getStart("left");
  const columnSize = column.getSize();

  return {
    left: isPinned === "left" ? `${columnGetStart}px` : undefined,
    width: columnSize,
  };
};

export const columnsIdWithoutText = [
  defaultColumnsID.addColumn,
  defaultColumnsID.select,
  defaultColumnsID.rowActions,
] as string[];

export const moveColumnToLastPosition = (
  columnOrder: string[],
  columnId: string,
) => {
  const newColumnOrder = columnOrder.filter(
    id => id !== columnId && id !== defaultColumnsID.addColumn,
  );
  newColumnOrder.push(columnId);
  newColumnOrder.push(defaultColumnsID.addColumn);

  return [...newColumnOrder];
};
